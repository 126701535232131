import classNames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';

const CloseIcon = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
      <path d="M6 16C6 15.1716 6.67157 14.5 7.5 14.5H24.5C25.3284 14.5 26 15.1716 26 16C26 16.8284 25.3284 17.5 24.5 17.5H7.5C6.67157 17.5 6 16.8284 6 16Z" />
    </g>
  </svg>
);

const OpenIcon = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 7.5C17.5 6.67157 16.8284 6 16 6C15.1716 6 14.5 6.67157 14.5 7.5V14.5H7.5C6.67157 14.5 6 15.1716 6 16C6 16.8284 6.67157 17.5 7.5 17.5H14.5V24.5C14.5 25.3284 15.1716 26 16 26C16.8284 26 17.5 25.3284 17.5 24.5V17.5H24.5C25.3284 17.5 26 16.8284 26 16C26 15.1716 25.3284 14.5 24.5 14.5H17.5V7.5Z"
      />
    </g>
  </svg>
);

interface FAQQuestionProps {
  question: string;
  answer: string | React.ReactElement;
}

const FAQQuestion = ({ question, answer }: FAQQuestionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <li
      className={classNames(
        'bg-jungle-primary-default',
        'p-16 pl-24',
        'rounded-[12px]',
        'jungle-box-shadow-inset-half'
      )}
    >
      <div
        className="flex items-center justify-between w-full"
        onClick={() => setIsOpen(!isOpen)}
        aria-label="Toggle question"
        role="button"
      >
        <h3 className="text-jungle-dark-default font-bold text-20 leading-8 md:flex-1 text-left">{question}</h3>
        <div className="ml-8 fill-jungle-dark-default">{isOpen ? CloseIcon : OpenIcon}</div>
      </div>
      {isOpen && (
        <div className="pt-16">
          <hr className="text-jungle-dark-default" />
          <p className="pt-16 text-jungle-dark-default text-16 leading-7">{answer}</p>
        </div>
      )}
    </li>
  );
};

interface FAQProps {
  includeStonesUnderlay?: boolean;
  title?: string;
  darkTitle?: boolean;
  includeCharacters?: boolean;
  questions: FAQQuestionProps[];
}

const FAQ = ({
  title,
  includeStonesUnderlay = true,
  darkTitle = false,
  includeCharacters = false,
  questions
}: FAQProps) => {
  const { t } = useTranslation();

  return (
    <div
      id="faq"
      className={classNames(
        'pt-40',
        'lg:pt-[10vw]',
        'pb-64',
        'lg:pb-[140px]',
        'relative',
        includeCharacters && 'overflow-hidden',
        'scroll-mt-[140px]',
        'md:scroll-mt-[130px]',
        'lg:scroll-mt-[110px]'
      )}
    >
      <div className="container-slim relative z-20">
        <h2
          className={classNames(
            'font-black',
            'font-junglewars',
            darkTitle ? 'text-jungle-dark-dark' : 'text-jungle-primary-default',
            'text-center',
            'text-[30px]',
            'leading-[40px]',
            'md:text-[50px]',
            'md:leading-[64px]',
            'lg:text-[80px]',
            'lg:leading-[96px]',
            'mb-24',
            'md:mb-48',
            'lg:mb-56'
          )}
        >
          {title ?? t('Frequently asked questions')}
        </h2>
        <ul className="space-y-12 relative z-20">
          {questions.map(({ question, answer }, index) => (
            <FAQQuestion question={question} answer={answer} key={index} />
          ))}
          {includeCharacters && (
            <>
              <div className="absolute -left-[110px] top-0 z-[-1] w-[200px] h-[337px] -rotate-45 hidden xl:block">
                <img src="/images/faq-character-1.png" alt="" className="w-full" />
              </div>
              <div className="absolute -right-[160px] top-[130px] z-[-1] w-[200px] h-[400px] rotate-[190deg] hidden xl:block">
                <img src="/images/faq-character-2.png" alt="" className="w-full" />
              </div>
            </>
          )}
        </ul>
      </div>
      {includeStonesUnderlay && (
        <img
          src="/images/junglewars-stones.svg"
          alt=""
          className="absolute top-[1250px] sm:top-[730px] lg:top-[880px] xl:top-[800px] 2xl:top-[700px] -left-[5vw] right-0 mx-auto z-10 max-w-[1800px]"
        />
      )}
    </div>
  );
};

export default FAQ;
