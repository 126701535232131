import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';

import { getLeaderboardData } from '../../../services/utils';

import LeaderboardCard from './LeaderboardCard';
import LeaderboardEntry, { LeaderboardEntryProps } from './LeaderboardEntry';
import LeaderboardPagination from './LeaderboardPagination';

const SearchIcon = (
  <svg viewBox="0 0 18 18" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.6"
      d="M7.70789 2.57146C8.64928 2.57003 9.57288 2.82854 10.3773 3.31861C11.1817 3.80868 11.8359 4.51138 12.268 5.34958C12.7001 6.18778 12.8934 7.12907 12.8268 8.07016C12.7602 9.01125 12.4361 9.91575 11.8902 10.6844L11.3835 11.398L10.6714 11.9058C10.013 12.3734 9.25385 12.6787 8.45556 12.7969C7.65727 12.9151 6.84241 12.8429 6.07722 12.5861C5.31203 12.3293 4.61809 11.8952 4.0518 11.3191C3.48551 10.7429 3.06286 10.041 2.81819 9.27035C2.57351 8.49971 2.51373 7.68208 2.64369 6.88392C2.77366 6.08576 3.0897 5.3296 3.56613 4.67688C4.04257 4.02416 4.66595 3.49331 5.38562 3.12748C6.10529 2.76164 6.90093 2.57114 7.70789 2.57146ZM7.70789 6.95274e-06C6.49608 -0.00162341 5.30101 0.283498 4.21992 0.83217C3.13883 1.38084 2.20227 2.17756 1.48646 3.15749C0.770644 4.13743 0.295798 5.27289 0.100561 6.47148C-0.0946764 7.67006 -0.00478837 8.89791 0.36291 10.0551C0.730609 11.2123 1.36573 12.2662 2.21659 13.1309C3.06744 13.9956 4.11 14.6468 5.25942 15.0315C6.40883 15.4162 7.63263 15.5234 8.83122 15.3446C10.0298 15.1657 11.1693 14.7058 12.1571 14.0022L15.7717 17.6254C16.2708 18.1249 17.0803 18.1249 17.5787 17.6254L17.5973 17.6067C17.724 17.4925 17.8254 17.3529 17.8948 17.1969C17.9641 17.0409 18 16.872 18 16.7013C18 16.5305 17.9641 16.3616 17.8948 16.2056C17.8254 16.0496 17.724 15.91 17.5973 15.7958L13.982 12.1733C14.8005 11.0199 15.2863 9.66293 15.3861 8.25116C15.4859 6.83939 15.1958 5.42735 14.5477 4.16988C13.8996 2.91242 12.9185 1.8581 11.712 1.12254C10.5054 0.38699 9.12011 -0.00138561 7.70789 6.95274e-06Z"
      fill="inherit"
    />
  </svg>
);

const PER_PAGE = 10;

const LeaderboardTable = () => {
  const { t } = useTranslation();

  const [data, setData] = useState<LeaderboardEntryProps[] | null>(null);
  const [pageData, setPageData] = useState<LeaderboardEntryProps[] | null>();
  const [page, setPage] = useState(0);

  const MAX_PAGE = data ? (data.length < PER_PAGE ? 1 : Math.ceil(data.length / PER_PAGE)) : 1;

  const [searchTerm, setSearchTerm] = useState('');

  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      setPage(0);
      setData(await getLeaderboardData('junglewars'));
    })();
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (!dataLoaded) {
      setDataLoaded(true);
    }

    setPage(0);
    setPageData([...data.slice(0 * PER_PAGE, 1 * PER_PAGE)]);
  }, [data]);

  const handlePageChange = (page: number) => {
    if (!data) {
      return;
    }

    const adjustedPage = page + 1;
    const matchingPageData = [...data.slice((adjustedPage - 1) * PER_PAGE, adjustedPage * PER_PAGE)];

    setPage(page);
    setPageData([...matchingPageData]);

    if (typeof window !== 'undefined') {
      // not using smoothscrolling because when switching between pages with
      // different per-page count the dom height changes and looks weird
      // in conjunction with smoothscrolling
      window.scrollTo({ top: 0 });
    }
  };

  const getTable = () => {
    if (!dataLoaded || !data || !pageData) {
      return (
        <div className="mt-24 md:mt-32 lg:mt-56">
          <p className="text-[16px] leading-[24px] font-junglewars font-bold text-jungle-dark-dark text-center">
            <Trans>Loading Top 100 NFT leaders...</Trans>
          </p>
        </div>
      );
    }

    if (pageData.length === 0) {
      return (
        <div className="mt-24 md:mt-32 lg:mt-56">
          <p className="text-[16px] leading-[24px] font-junglewars font-bold text-jungle-dark-dark text-center">
            <Trans>No Stateful NFTs found</Trans>
          </p>
        </div>
      );
    }

    if (searchTerm !== '') {
      const filteredData = data.filter(
        (entry) =>
          (searchTerm !== '' && `#${entry.token_id}`.indexOf(searchTerm) !== -1) ||
          entry.wallet_address.toLowerCase().indexOf(searchTerm) !== -1
      );

      if (filteredData.length === 0) {
        return (
          <div className="mt-32 md:mt-48 lg:mt-80 flex flex-col items-center">
            <img
              src="/images/rankings-no-results.jpg"
              className="rounded-full w-[120px] h-[120px]"
              alt="No results illustration"
            />
            <p className="text-[18px] leading-[24px] font-junglewars font-black text-jungle-dark-dark text-center mt-16">
              <Trans>No Stateful NFTs found</Trans>
            </p>
            <p className="text-[16px] leading-[20px] font-junglewars text-jungle-dark-dark text-center mt-8 max-w-[220px] mx-auto">
              <Trans>Try changing your search by typing a different word.</Trans>
            </p>
          </div>
        );
      }

      return (
        <ul className="space-y-4 xs:space-y-8 md:mt-16">
          {filteredData.map((entry, index) => (
            <LeaderboardEntry
              key={index}
              {...entry}
              position={data.findIndex((searchEntry) => searchEntry.token_id === entry.token_id) + 1}
              name={`${t('Paima Volcaneer')} #${entry.token_id}`}
            />
          ))}
        </ul>
      );
    } else {
      return (
        <div className="flex flex-col flex-1 justify-between">
          <ul className="space-y-4 xs:space-y-8 md:mt-16">
            {pageData.map((entry, index) => {
              const position = data.findIndex((searchEntry) => searchEntry.token_id === entry.token_id) + 1;
              const hasPodiumPosition = index <= 2 && page === 0 && data.length > 3;

              return (
                <LeaderboardEntry
                  key={index}
                  {...entry}
                  position={position}
                  name={`${t('Paima Volcaneer')} #${entry.token_id}`}
                  wrapperClassname={hasPodiumPosition ? 'md:hidden' : ''}
                />
              );
            })}
          </ul>
          <div className="mt-24 flex justify-end relative z-30">
            <LeaderboardPagination
              handlePageClick={(page) => handlePageChange(page)}
              pageCount={MAX_PAGE}
              forcedPage={page}
            />
          </div>
        </div>
      );
    }
  };

  const getLeaderboardCards = () => {
    if (!dataLoaded || !data || !pageData || data.length <= 3 || page !== 0) {
      return null;
    }

    return (
      <div className="hidden md:flex gap-[16px] lg:gap-[24px] mb-[32px] mt-[52px] items-end">
        <LeaderboardCard
          {...data[1]}
          position={data.findIndex((searchEntry) => searchEntry.token_id === data[1].token_id) + 1}
          name={`${t('Paima Volcaneer')} #${data[1].token_id}`}
        />
        <LeaderboardCard
          {...data[0]}
          position={data.findIndex((searchEntry) => searchEntry.token_id === data[0].token_id) + 1}
          name={`${t('Paima Volcaneer')} #${data[0].token_id}`}
          largeVariant
        />
        <LeaderboardCard
          {...data[2]}
          position={data.findIndex((searchEntry) => searchEntry.token_id === data[2].token_id) + 1}
          name={`${t('Paima Volcaneer')} #${data[2].token_id}`}
        />
      </div>
    );
  };

  return (
    <div className="bg-[#FFFBE7] ranking-box-shadow-inset-negative rounded-[12px] p-10 relative z-20">
      <div className="bg-[#ECE5C9] ranking-box-shadow-inset rounded-[12px] px-16 py-24 md:px-24 md:py-32 lg:p-32 lg:min-h-[600px] flex flex-col">
        <div className="flex flex-col md:flex-row md:justify-between md:items-center">
          <h1 className="font-junglewars font-black text-jungle-dark-dark text-[40px] leading-[56px]">
            <Trans>Top 100 NFTs</Trans>
          </h1>
          <div className="w-full relative md:max-w-[380px] bg-[#FEFCE7] rounded-[4px] pl-8 pr-32 ranking-box-shadow-inset-search border border-[#7A7B6F]">
            <input
              type="text"
              placeholder={t('Search by #ID or Address...')}
              className="bg-transparent px-8 py-4 focus:outline-none w-full font-junglewars"
              onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
              value={searchTerm}
            />
            <button className="w-[18px] h-[18px] absolute top-0 bottom-0 m-auto right-16">{SearchIcon}</button>
          </div>
        </div>
        <div className="mt-16 flex flex-col flex-1">
          {getLeaderboardCards()}
          <div className="hidden md:flex md:items-center md:justify-between md:px-12">
            <div className="md:flex md:items-center">
              <div className="md:w-[80px]">
                <p className="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-junglewars font-black text-jungle-dark-dark">
                  <Trans>Rank</Trans>
                </p>
              </div>
              <div className="md:w-[250px]">
                <p className="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-junglewars font-black text-jungle-dark-dark">
                  <Trans>Stateful NFT</Trans>
                </p>
              </div>
            </div>
            <div className="md:ml-40 md:gap-x-8 md:flex md:items-center">
              <div className="md:w-[60px] lg:w-[96px]">
                <p className="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-junglewars font-black text-jungle-dark-dark">
                  <Trans>Wins</Trans>
                </p>
              </div>
              <div className="md:w-[60px] lg:w-[96px]">
                <p className="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-junglewars font-black text-jungle-dark-dark">
                  <Trans>Draws</Trans>
                </p>
              </div>
              <div className="md:w-[60px] lg:w-[96px]">
                <p className="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-junglewars font-black text-jungle-dark-dark">
                  <Trans>Losses</Trans>
                </p>
              </div>
              <div className="md:w-[60px] lg:w-[96px]">
                <p className="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-junglewars font-black text-jungle-dark-dark">
                  <Trans>Points</Trans>
                </p>
              </div>
            </div>
          </div>
          {getTable()}
        </div>
      </div>
    </div>
  );
};

export default LeaderboardTable;
