import { DotsHorizontalIcon } from '@heroicons/react/outline';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import React from 'react';
import ReactPaginate from 'react-paginate';

interface LeaderboardPaginationProps {
  handlePageClick: (page: number) => void;
  pageCount: number;
  forcedPage?: number;
}

const LeaderboardPagination: React.FC<LeaderboardPaginationProps> = ({
  handlePageClick,
  pageCount,
  forcedPage
}: LeaderboardPaginationProps) => {
  return (
    <ReactPaginate
      previousLabel={<ChevronLeftIcon className="h-16 w-16" aria-hidden="true" />}
      nextLabel={<ChevronRightIcon className="h-16 w-16" aria-hidden="true" />}
      breakLabel={<DotsHorizontalIcon className="h-16 w-16" aria-hidden="true" />}
      pageCount={pageCount}
      marginPagesDisplayed={3}
      pageRangeDisplayed={3}
      containerClassName={'flex md:inline-flex md:flex-nowrap justify-center gap-12'}
      onPageChange={(e) => handlePageClick(e.selected)}
      pageClassName={
        'w-[28px] h-[28px] bg-volcaneers-controls-default ranking-box-shadow-inset-entry border-[#7A7B6F] font-junglewars hover:bg-volcaneers-controls-dark flex justify-center items-center rounded text-14 text-jungle-dark-default font-semibold font-base text-center p-0'
      }
      previousClassName={'hidden'}
      nextClassName={'hidden'}
      breakClassName={'flex flex justify-center items-center text-jungle-dark-default'}
      activeClassName={'bg-jungle-dark-default !text-white hover:!bg-jungle-dark-dark'}
      pageLinkClassName={'!no-underline w-full h-full flex justify-center items-center pointer-cursor'}
      forcePage={forcedPage}
    />
  );
};

export default LeaderboardPagination;
