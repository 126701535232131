import classNames from 'classnames';
import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

import { formatNumber, getLeaderboardPosition } from '../../../services/utils';

import CopyableAddress from '../CopyableAddress';
import { LeaderboardEntryProps } from './LeaderboardEntry';

interface LeaderboardCardProps extends LeaderboardEntryProps {
  largeVariant?: boolean;
  darkVisual?: boolean;
}

const LeaderboardCard = ({
  wallet_address,
  avatar_url,
  wins,
  draws,
  losses,
  position,
  score,
  name,
  largeVariant = false,
  darkVisual = false
}: LeaderboardCardProps) => {
  return (
    <div
      className={classNames(
        'relative',
        'flex-1',
        'flex',
        'flex-col',
        'items-center',
        'justify-center',
        'ranking-box-shadow-inset-entry',
        'bg-[#FEFCE7]',
        'rounded-[12px]',
        'px-[16px]',
        'lg:px-[32px]',
        'xl:px-[40px]',
        largeVariant ? 'py-[32px] pt-[56px]' : 'py-[32px]'
      )}
    >
      <div className="absolute w-[60px] h-[60px] -top-[30px] left-[16px]">
        {getLeaderboardPosition(position) === 1 && <img src="/images/medal-first.webp" alt="First place medal" />}
        {getLeaderboardPosition(position) === 2 && <img src="/images/medal-second.webp" alt="Second place medal" />}
        {getLeaderboardPosition(position) === 3 && <img src="/images/medal-third.webp" alt="Third place medal" />}
      </div>
      <div
        className={classNames(
          largeVariant ? 'w-[110x] min-w-[110px] h-[114px]' : 'w-[80px] min-w-[80px] h-[84px]',
          'rounded-[8px]',
          'jungle-box-shadow-inset-blue',
          'mb-[16px] lg:mb-[24px]'
        )}
      >
        <img
          src={avatar_url ?? ''}
          alt=""
          className={classNames(largeVariant ? 'w-[110px] h-[110px]' : 'w-[80px] h-[80px]', 'rounded-[8px]')}
        />
      </div>
      <div
        className={classNames(
          'flex',
          'flex-col',
          'justify-center',
          'items-center',
          largeVariant ? 'mb-[40px]' : 'mb-[16px]'
        )}
      >
        <p className="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-junglewars font-black text-jungle-dark-dark">
          {name}
        </p>
        {wallet_address !== '' && (
          <CopyableAddress
            address={wallet_address}
            successMessageClasses="text-jungle-dark-dark hidden md:inline"
            textClasses="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-junglewars font-normal text-jungle-dark-dark"
          />
        )}
      </div>
      <div className="grid grid-cols-4 sm:grid-cols-2 xs:grid-cols-4 mt-12 sm:gap-x-8 sm:gap-y-4">
        <div className="md:w-[60px] lg:w-[96px]">
          <p className="uppercase text-[10px] font-junglewars font-black text-jungle-dark-dark">
            <Trans>Wins</Trans>
          </p>
          <p className="uppercase text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-junglewars font-bold text-jungle-dark-dark">
            {wins !== undefined && formatNumber(wins)}
          </p>
        </div>
        <div className="md:w-[60px] lg:w-[96px]">
          <p className="uppercase text-[10px] font-junglewars font-black text-jungle-dark-dark">
            <Trans>Draws</Trans>
          </p>
          <p className="uppercase text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-junglewars font-bold text-jungle-dark-dark">
            {draws !== undefined && formatNumber(draws)}
          </p>
        </div>
        <div className="md:w-[60px] lg:w-[96px]">
          <p className="uppercase text-[10px] font-junglewars font-black text-jungle-dark-dark">
            <Trans>Losses</Trans>
          </p>
          <p className="uppercase text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-junglewars font-bold text-jungle-dark-dark">
            {losses !== undefined && formatNumber(losses)}
          </p>
        </div>
        <div className="md:w-[60px] lg:w-[96px]">
          <p className="uppercase text-[10px] font-junglewars font-black text-jungle-dark-dark">
            <Trans>Points</Trans>
          </p>
          <p className="uppercase text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-junglewars font-bold text-jungle-dark-dark">
            {score !== undefined && formatNumber(score)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LeaderboardCard;
