import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import CookieConsentBanner from '../../components/CookieConsentBanner';
import Footer from '../../components/v2/Footer';
import Header from '../../components/v2/header/Header';
import Layout from '../../components/v2/Layout';
import RankingsLayout from '../../components/v2/leaderboard/LeaderboardLayout';

const LeaderBoardPage = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('Leaderboard')}>
      <Header showSubHeader="junglewars" redirectToBuyNft />
      <CookieConsentBanner />
      <RankingsLayout />
      <Footer />
    </Layout>
  );
};

export default LeaderBoardPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
