import classNames from 'classnames';
import React from 'react';

import { LeaderboardEntryType } from '../../../services/types';
import { formatNumber, getLeaderboardPosition } from '../../../services/utils';

import CopyableAddress from '../CopyableAddress';

export interface LeaderboardEntryProps extends LeaderboardEntryType {
  position: number | string;
  avatar_url?: string;
  name?: string;
  wallet_address: string;
  wrapperClassname?: string;
}

const LeaderboardEntry = ({
  wallet_address,
  avatar_url,
  wins,
  draws,
  losses,
  position,
  score,
  name,
  wrapperClassname
}: LeaderboardEntryProps) => {
  const hasPodiumPosition =
    getLeaderboardPosition(position) === 1 ||
    getLeaderboardPosition(position) === 2 ||
    getLeaderboardPosition(position) === 3;

  return (
    <li
      className={classNames(
        'px-12',
        'py-16',
        'ranking-box-shadow-inset-entry',
        'bg-[#FEFCE7]',
        'rounded-[12px]',
        wrapperClassname
      )}
    >
      <div className="xs:flex xs:justify-between xs:items-center">
        <div className="flex items-center">
          <div className="md:w-[80px]">
            <div className="w-[32px] h-[32px] md:w-[40px] md:h-[40px] mr-8 sm:mr-12 md:mr-24 flex items-center md:block">
              {hasPodiumPosition ? (
                <>
                  <div className="md:hidden w-[32px] h-[32px]">
                    {getLeaderboardPosition(position) === 1 && (
                      <img src="/images/medal-first.webp" alt="First place medal" />
                    )}
                    {getLeaderboardPosition(position) === 2 && (
                      <img src="/images/medal-second.webp" alt="Second place medal" />
                    )}
                    {getLeaderboardPosition(position) === 3 && (
                      <img src="/images/medal-third.webp" alt="Third place medal" />
                    )}
                  </div>
                  <div className="hidden ranking-box-shadow-inset-number rounded-[4px] md:flex items-center justify-center border-2 border-[#7A7B6F] w-[32px] h-[32px] md:w-[40px] md:h-[40px]">
                    <span className="jungle-orange-text text-[18px] leading-[24px] font-junglewars">{position}</span>
                  </div>
                </>
              ) : (
                <div className="ranking-box-shadow-inset-number rounded-[4px] flex items-center justify-center border-2 border-[#7A7B6F] w-[32px] h-[32px] md:w-[40px] md:h-[40px]">
                  <span className="jungle-orange-text text-[18px] leading-[24px] font-junglewars">{position}</span>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center min-w-[160px] md:min-w-fit md:w-[250px]">
            <div className="w-[48px] min-w-[48px] h-[52px] rounded-[8px] mr-12 jungle-box-shadow-inset-blue">
              <img src={avatar_url ?? ''} alt="" className="w-[48px] h-[48px] rounded-[8px]" />
            </div>
            <div>
              <p className="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-junglewars font-black text-jungle-dark-dark">
                {name}
              </p>
              {wallet_address !== '' && (
                <CopyableAddress
                  address={wallet_address}
                  successMessageClasses="text-jungle-dark-dark hidden md:inline"
                  textClasses="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-junglewars font-normal text-jungle-dark-dark"
                />
              )}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 mt-12 xs:gap-x-8 xs:gap-y-4 xs:mt-0 xs:ml-8 xs:min-w-[240px] md:min-w-fit md:w-[240px] md:ml-40">
          <div className="md:w-[60px] lg:w-[96px]">
            <p className="md:hidden uppercase text-[10px] font-junglewars font-black text-jungle-dark-dark">Wins</p>
            <p className="uppercase text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-junglewars font-bold text-jungle-dark-dark">
              {wins !== undefined && formatNumber(wins)}
            </p>
          </div>
          <div className="md:w-[60px] lg:w-[96px]">
            <p className="md:hidden uppercase text-[10px] font-junglewars font-black text-jungle-dark-dark">Draws</p>
            <p className="uppercase text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-junglewars font-bold text-jungle-dark-dark">
              {draws !== undefined && formatNumber(draws)}
            </p>
          </div>
          <div className="md:w-[60px] lg:w-[96px]">
            <p className="md:hidden uppercase text-[10px] font-junglewars font-black text-jungle-dark-dark">Losses</p>
            <p className="uppercase text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-junglewars font-bold text-jungle-dark-dark">
              {losses !== undefined && formatNumber(losses)}
            </p>
          </div>
          <div className="md:w-[60px] lg:w-[96px]">
            <p className="md:hidden uppercase text-[10px] font-junglewars font-black text-jungle-dark-dark">Points</p>
            <p className="uppercase text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-junglewars font-bold text-jungle-dark-dark">
              {score !== undefined && formatNumber(score)}
            </p>
          </div>
        </div>
      </div>
    </li>
  );
};

export default LeaderboardEntry;
