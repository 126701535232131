import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { getLocalizedLink } from '../../../utils/locale';

import FAQ from '../jungle_wars/FAQ';
import FullwidthIllustration from '../volcaneers/FullwidthIllustration';
import LeaderboardTable from './LeaderboardTable';

const OvalIllustration = (
  <svg viewBox="0 0 1600 157" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.99194 0.0136864V153.05H800H1600V0H1599.03C1468.54 89.8812 1159.93 153.05 800 153.05C440.092 153.05 131.494 89.8876 0.99194 0.0136864Z"
      fill="inherit"
    />
    <path d="M0 152.063H1600V157H0V152.063Z" fill="inherit" />
  </svg>
);

const LeaderboardLayout = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="bg-[url('/images/rankings-background.webp')] bg-cover bg-no-repeat bg-center pb-[25vw] md:pb-[15vw] 2xl:pb-[8vw]">
        <div className="container-slim">
          <div className="pt-16 md:pt-32 lg:pt-64 -ml-16 -mr-16 sm:ml-0 sm:mr-0 pb-40 md:pb-80">
            <LeaderboardTable />
          </div>
        </div>
      </div>
      <div className="bg-[url('/images/rankings-faq-background.webp')] bg-cover bg-no-repeat bg-center jungle-oval-clip">
        <div className="pt-[12vw] relative -mt-[15vw]">
          <FAQ
            title={t('FAQ')}
            includeStonesUnderlay={false}
            darkTitle
            includeCharacters
            questions={[
              {
                question: t('Where I can Play Jungle Wars: NFT Rumble?'),
                answer: (
                  <Trans
                    i18nKey="To play Jungle Wars, go to the website's navbar and click on the 'Play Free' option or by clicking this link. This will take you to the game's main page, where you can begin playing immediately. Please note that you need to have mADA in your web3 wallet."
                    components={[
                      <a
                        className="underline cursor-pointer"
                        href="https://paimastudios.com/junglewars/play"
                        target="_blank"
                      />
                    ]}
                  />
                )
              },
              {
                question: t(
                  'What factors are taken into account when calculating my ranking in Jungle Wars NFT Rumble?'
                ),
                answer: t(
                  'In Jungle Wars NFT Rumble, your ranking is based on points accumulated for wins, draws, and losses per your Stateful NFT. Each win grants 2 points, draws deduct 1 point, and losses deduct 2 points. The higher your points, the higher your ranking!'
                )
              },
              {
                question: t('What are Stateful NFTs?'),
                answer: (
                  <Trans
                    i18nKey="Stateful NFTs are a new class of NFTs developed by Paima Studios. Unlike traditional NFTs, Stateful NFTs carry game state such as stats or achievements. The Volcaneer NFTs, used in Jungle Wars: NFT Rumble, are the first iteration of Stateful NFTs and are an exciting first taste of this brand new technology."
                    components={[
                      <a href="https://youtu.be/oE33ON9qZq4" target="_blank" className="underline cursor-pointer" />
                    ]}
                  />
                )
              },
              {
                question: t('Where I can get a Stateful NFT and Play Jungle Wars?'),
                answer: (
                  <Trans
                    i18nKey="The Paima Volcaneers NFT are the first collection of Stateful NFTs. There is an unlimted supply of them, released in themed batches, with a low 10 mADA each and pioneering the Value Era of NFTs."
                    components={[
                      <a
                        href={getLocalizedLink('https://paimastudios.com/volcaneers', i18n.language)}
                        target="_blank"
                        className="underline cursor-pointer"
                      />
                    ]}
                  />
                )
              },
              {
                question: t('How do I use Stateful NFTs in Jungle Wars NFT Rumble on Milkomeda?'),
                answer: t(
                  "To use your Stateful NFT in Jungle Wars: NFT Rumble, click on the blank profile image in the top left corner of the game home screen, select the Volcaneer you want to play with, and then click on the 'Pick' button and submit the transaction."
                )
              },
              {
                question: t('What NFTs are supported in Jungle Wars NFT Rumble on Milkomeda?'),
                answer: t(
                  "Jungle Wars: NFT Rumble allows you to use your Paima Volcaneers in game. Volcaneers are Paima Studio's first iteration of Stateful NFTs and carry the owner's evolving on-chain gaming record."
                )
              }
            ]}
          />
          <FullwidthIllustration
            illustration={OvalIllustration}
            containerClasses="pt-[10vw] fill-volcaneers-black"
            classes="relative -bottom-[2px]"
          />
        </div>
      </div>
    </>
  );
};

export default LeaderboardLayout;
